'use strict';

window.router
	.get('/', (req, context) => {
		window.do.when(`#home-landing`).then(region => {
			let scrollTarget = region.getBoundingClientRect().top + window.pageYOffset;

			if(/--offset/gi.test(region.querySelector(`.${window.namespace}-section__content`).getAttribute('class')))
			{
				let content = region.querySelector(`.${window.namespace}-section__content--offset`);
				let offset = Math.abs(parseInt(window.getComputedStyle(content).getPropertyValue('margin-top')));

				scrollTarget -= offset * 2;
			}

			if(document.querySelector(`#header.${window.namespace}-document__header--fixed`))
			{
				let header = document.getElementById('header');
				
				scrollTarget -= header.getBoundingClientRect().height;
			}

			window.setTimeout(() => {
				window.scrollTo({
					behavior: 'smooth',
					top: Math.floor(scrollTarget)
				});
			});
		});
	});