'use strict';

{
	let rootClassname = `${window.namespace}-module`;
	let inputClassname = `${rootClassname}__input`
	let messageClassname = `${window.namespace}-module__messaging`;
	
	window.do.when('#zip-lookup').then(module => {
		module.api = ((host) => {
			switch(host.toLowerCase())
			{
				case 'uspsconnect.com':
				case 'www.uspsconnect.com':
				{
					return 'https://uspsconnect.com/service-annex/api/collections/get/ZIP_Codes';
				}

				default:
				{
					return 'https://stage.usps-connect.mrmclient.com/service-annex/api/collections/get/ZIP_Codes';

					break;
				}
			}
		})(location.hostname);
		
		module.apiToken = ((host) => {
			switch(host.toLowerCase())
			{
				case 'uspsconnect.com':
				case 'www.uspsconnect.com':
				{
					return 'd78586913fe39cbee0f40f82a41f31';
				}

				default:
				{
					return 'd78586913fe39cbee0f40f82a41f31';

					break;
				}
			}
		})(location.hostname);

		window.do.always(() => {
			let selector = `${inputClassname}:not([data-assigned])`;

			if(document.querySelectorAll(`.${selector}`).length)
			{
				document.querySelectorAll(`.${selector}`).forEach(field => 
				{
					let module = field.closest(`.${rootClassname}`);

					field.validate = function()
					{
						let input = this.querySelector(`.${inputClassname}-native`);
						let pattern = (input.pattern) ? input.pattern : '^.+$';
						pattern = new RegExp(pattern, 'gi');

						if(!pattern.test(input.value))
						{
							
							field.classList.add(`${inputClassname}--invalid`);
						}
						
						else
						{
							field.classList.remove(`${inputClassname}--invalid`);
						}
					};

					{
						let input = field.querySelector(`.${inputClassname}-native`);
						
						if(input.value && input.value !== '')
						{
							input.classList.add(`${inputClassname}-native--filled`);
						}
						
						[
							'input',
							'blur'
						].forEach(eventListener => {
							input.addEventListener(eventListener, (event) => {
								if(input.value && input.value !== '')
								{
									field.classList.add(`${inputClassname}--dirty`);
									input.classList.add(`${inputClassname}-native--filled`);
								}
								
								else
								{
									input.classList.remove(`${inputClassname}-native--filled`);
								}

								input.value = input.value.replace(/[^\d|-]/gi, "");

								field.validate();
							});
						});
						
						let timer = null;
						let checkAvailability = () => {
							if(!input.dataset.loading || input.dataset.loading !== 'true')
							{
								let messaging = module.querySelector(`.${messageClassname}`);
								messaging.innerHTML = `<span class='material-icons-outlined' aria-hidden='true'>error_outline</span> Please enter a valid ZIP Code<sup>™</sup>`;

								if(!field.classList.contains(`${inputClassname}--invalid`))
								{
									input.dataset.loading = true;
									input.blur();
									
									input.setAttribute('readonly', '');
									messaging.innerHTML = `Loading`;

									axios
										.post(module.api, {
											"filter": {
												"active": true,
												"zip5": parseInt(input.value)
											}
										})
										.then(response => {
											//- This is the default state: Assume the ZIP is not in service.
											messaging.innerHTML = `<span class='material-icons-outlined' aria-hidden='true'>close</span> Sorry, we’re not in your area yet.`;
											
											if(response.data.entries && Array.isArray(response.data.entries) && response.data.entries.length > 0)
											{
												let service = response.data.entries[0];

												//- Messaging for general service availability
												if(service.available)
												{
													messaging.innerHTML = `<span class='material-icons-outlined' aria-hidden='true'>check</span> Good news! We'll be in your area soon!`;

													if(!isNaN(Date.parse(service.available)))
													{
														let friendlyDateString = new Date(service.available).toLocaleDateString();
														
														messaging.innerHTML = `<span class='material-icons-outlined' aria-hidden='true'>check</span> Good news! We'll be in your area starting ${friendlyDateString}!`;

														if(new Date() > new Date(service.available))
														{
															messaging.innerHTML = `<span class='material-icons-outlined' aria-hidden='true'>check</span> Great news, we’re in your area!`;
														}
													}
												}
												
												//- Messaging for package pickup service availability
												if(service.package_pickup_service)
												{
													messaging.innerHTML = `<span class='material-icons-outlined' aria-hidden='true'>check</span> Great news, we're in your area and you're eligible. Package pickup is available for next-day delivery.<button class='${window.namespace}-reference' role='link' aria-label=''>3</button>`;
												}
											}
											
											input.removeAttribute('readonly');
											input.removeAttribute('data-loading');
											input.focus();
										})
										.catch(err => {
											messaging.innerHTML = `<span class='material-icons-outlined' aria-hidden='true'>close</span> Sorry, something went wrong.`;
											
											messaging.removeAttribute('aria-hidden');
											messaging.removeAttribute('hidden');

											input.removeAttribute('readonly');
											input.removeAttribute('data-loading');
											input.focus();
										});
								}
							}
						};

						input.addEventListener('input', event => {
							clearTimeout(timer);

							timer = setTimeout(checkAvailability, 500);
						});
					}
					
					field.dataset.assigned = `${window.namespace}`;
				});
			}
		});
	});
}