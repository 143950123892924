'use strict';

{
	let trackVendor = () => 
	{
		let a = navigator.userAgent;
		let c = [];
		let t = (p) => 
		{
			let r = new RegExp(p, 'gi').test(a);

			return r;
		};

		switch(true)
		{
			// Firefox 
			case t('Firefox'):
			{
				c.push('firefox');

				switch(true)
				{
					case t('servo'):
					{
						c.push('servo');

						break;
					}
					
					default:
					{
						c.push('gecko');

						break;
					}
				}

				break;
			}

			// Opera 
			case t('\\sOPR'):
			case t('\\sOpera'):
			{
				c.push('opera');
				c.push('blink');

				break;
			}

			// Microsoft Edge (old) 
			case t('\\sEdge'):
			{
				c.push('edge');
				c.push('edgehtml');

				break;
			}

			// Microsoft Edge (new) 
			case t('\\sEdg'):
			{
				c.push('edge');
				c.push('blink');

				break;
			}

			// Google Chrome 
			case t('\\sChrome'):
			{
				c.push('chrome');
				c.push('blink');

				break;
			}

			// Apple Safari 
			case t('\\sSafari'):
			{
				c.push('safari');
				c.push('webkit');

				break;
			}

			default:
			{
				c.push('unknown');
				
				break;
			}
		}
		
		document.documentElement.dataset.navigator = c.join(' ');
	
		window.requestAnimationFrame(trackVendor);
	};
	
	window.requestAnimationFrame(trackVendor);
}