'use strict';

window.router
	.always(context => {
		set_iom_mac:
		{
			let query = location.search.substring(1);
			query = '{"' + query.replace(/&/g, '","').replace(/=/g,'":"') + '"}';
			query = query.replace('{""}', '{}');
			query = JSON.parse(query, function(key, value)
			{
				return (key === "") ? value : decodeURIComponent(value)
			})

			if(query.iom)
			{
				if(/-/gi.test(query.iom))
				{
					window.macCode = query.iom.split('-')[0];
					window.iomCode = query.iom;
				}
			}
			
			else
			{
				window.macCode = null;
				window.iomCode = null;
			}
		}
	});